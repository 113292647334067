import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'BANORTE',
  title: 'Actividades de voluntariado',
  logo: Logo,
  catalogTalents: true,
  logo_width: '200px',
  theme: {
    primary: '#E8002A',
    secondary: '#5E504E',
  },
  community: true,
};

export default config;
